/* GRID */
/* row */
.row {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0; }
  .row:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }

.flex-row {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap; }

/* FLOAT COLUMNS */
[class*='col-'] {
  position: relative;
  padding: 0;
  margin: 0;
  float: left; }

/* COLUMNS */
.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

/* COLUMNS ADAPTIVE */
/* media screen lg */
@media screen and (max-width: 1200px) {
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; } }

/* media screen md */
@media screen and (max-width: 900px) {
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; } }

/* media screen sm */
@media screen and (max-width: 640px) {
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; } }

/* media screen xs */
@media screen and (max-width: 480px) {
  .col-xs-1 {
    width: 8.33333%; }
  .col-xs-2 {
    width: 16.66667%; }
  .col-xs-3 {
    width: 25%; }
  .col-xs-4 {
    width: 33.33333%; }
  .col-xs-5 {
    width: 41.66667%; }
  .col-xs-6 {
    width: 50%; }
  .col-xs-7 {
    width: 58.33333%; }
  .col-xs-8 {
    width: 66.66667%; }
  .col-xs-9 {
    width: 75%; }
  .col-xs-10 {
    width: 83.33333%; }
  .col-xs-11 {
    width: 91.66667%; }
  .col-xs-12 {
    width: 100%; } }

/* CARDS (inline columns) */
[class*='card-'] {
  display: inline-block;
  vertical-align: text-top;
  position: relative;
  padding: 0 15px; }
  [class*='card-'] + [class*='card-'] {
    margin-left: -0.3em; }

/* CARDS */
.card-1 {
  width: 8.33333%; }

.card-2 {
  width: 16.66667%; }

.card-3 {
  width: 25%; }

.card-4 {
  width: 33.33333%; }

.card-5 {
  width: 41.66667%; }

.card-6 {
  width: 50%; }

.card-7 {
  width: 58.33333%; }

.card-8 {
  width: 66.66667%; }

.card-9 {
  width: 75%; }

.card-10 {
  width: 83.33333%; }

.card-11 {
  width: 91.66667%; }

.card-12 {
  width: 100%; }

/* CARDS ADAPTIVE */
/* media screen lg */
@media screen and (max-width: 1200px) {
  .card-lg-1 {
    width: 8.33333%; }
  .card-lg-2 {
    width: 16.66667%; }
  .card-lg-3 {
    width: 25%; }
  .card-lg-4 {
    width: 33.33333%; }
  .card-lg-5 {
    width: 41.66667%; }
  .card-lg-6 {
    width: 50%; }
  .card-lg-7 {
    width: 58.33333%; }
  .card-lg-8 {
    width: 66.66667%; }
  .card-lg-9 {
    width: 75%; }
  .card-lg-10 {
    width: 83.33333%; }
  .card-lg-11 {
    width: 91.66667%; }
  .card-lg-12 {
    width: 100%; } }

/* media screen md */
@media screen and (max-width: 900px) {
  .card-md-1 {
    width: 8.33333%; }
  .card-md-2 {
    width: 16.66667%; }
  .card-md-3 {
    width: 25%; }
  .card-md-4 {
    width: 33.33333%; }
  .card-md-5 {
    width: 41.66667%; }
  .card-md-6 {
    width: 50%; }
  .card-md-7 {
    width: 58.33333%; }
  .card-md-8 {
    width: 66.66667%; }
  .card-md-9 {
    width: 75%; }
  .card-md-10 {
    width: 83.33333%; }
  .card-md-11 {
    width: 91.66667%; }
  .card-md-12 {
    width: 100%; } }

/* media screen sm */
@media screen and (max-width: 640px) {
  .card-sm-1 {
    width: 8.33333%; }
  .card-sm-2 {
    width: 16.66667%; }
  .card-sm-3 {
    width: 25%; }
  .card-sm-4 {
    width: 33.33333%; }
  .card-sm-5 {
    width: 41.66667%; }
  .card-sm-6 {
    width: 50%; }
  .card-sm-7 {
    width: 58.33333%; }
  .card-sm-8 {
    width: 66.66667%; }
  .card-sm-9 {
    width: 75%; }
  .card-sm-10 {
    width: 83.33333%; }
  .card-sm-11 {
    width: 91.66667%; }
  .card-sm-12 {
    width: 100%; } }

/* media screen xs */
@media screen and (max-width: 480px) {
  .card-xs-1 {
    width: 8.33333%; }
  .card-xs-2 {
    width: 16.66667%; }
  .card-xs-3 {
    width: 25%; }
  .card-xs-4 {
    width: 33.33333%; }
  .card-xs-5 {
    width: 41.66667%; }
  .card-xs-6 {
    width: 50%; }
  .card-xs-7 {
    width: 58.33333%; }
  .card-xs-8 {
    width: 66.66667%; }
  .card-xs-9 {
    width: 75%; }
  .card-xs-10 {
    width: 83.33333%; }
  .card-xs-11 {
    width: 91.66667%; }
  .card-xs-12 {
    width: 100%; } }
